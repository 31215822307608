import React, { useEffect } from "react"

import { fetchShipping, updateCountry } from "./cartSlice"
import { useSelector } from "react-redux"
import { AppState, useAppDispatch } from "../../store"

import classes from "./OrderSummary.module.css"
import { CartItem } from "./types/CartItem"
import { Country } from "./types/Country"
import { currency } from "../../util/formatter"
import { ShippingCosts } from "../../api/schema"

const totals = (items: CartItem[]) =>
  items.reduce(
    (total, item) => ({
      amount: total.amount + item.quantity * item.price,
      weight: total.weight + item.quantity * item.weight,
    }),
    { amount: 0.0, weight: 0.0 }
  )

const shippingForWeightAndCountry = (
  weight: number,
  countryCode: string,
  shipping: ShippingCosts[]
) => {
  const countryShipping = shipping.find(
    (s) => s.country.toLowerCase() === countryCode.toLowerCase()
  )
  if (countryShipping === undefined) {
    throw new Error(`No shipping info found for country code ${countryCode}.`)
  }
  const matchingTier = countryShipping?.tiers
    .slice()
    .sort((a, b) => (a.maxWeight ?? Infinity) - (b.maxWeight ?? Infinity))
    .find((tier) => !tier.maxWeight || tier.maxWeight >= weight)
  if (matchingTier === undefined) {
    throw new Error(
      `Couldn't calculate ${countryCode.toUpperCase()} shipping for ${weight} g`
    )
  }
  return matchingTier.price
}

type OrderSummaryProps = {
  countryEditable?: boolean
  itemized?: boolean
}

const OrderSummary = ({
  countryEditable = false,
  itemized = false,
}: OrderSummaryProps) => {
  const dispatch = useAppDispatch()
  const {
    customer: { country },
    items,
    shippingCosts,
    isLoadingShipping,
    error,
  } = useSelector((state: AppState) => state.cart)

  useEffect(() => {
    if (shippingCosts === null && !error && !isLoadingShipping) {
      dispatch(fetchShipping())
    }
  }, [dispatch, shippingCosts, error, isLoadingShipping])

  const { amount, weight } = totals(items)

  const shipping =
    shippingCosts !== null
      ? shippingForWeightAndCountry(weight, country, shippingCosts)
      : null

  const notesPreview = (notes: string[]) => {
    let full = notes.filter((n) => !!n).join("; ")
    return full
      ? `Voor ${full}`
      : `Geen opdracht${notes.length !== 1 ? "en" : ""}`
  }

  return (
    <table className={classes.OrderSummary}>
      <tbody>
        {itemized ? (
          items.map((item) => (
            <tr key={item.book} className={classes.itemRow}>
              <td className={classes.itemCell}>
                <span>
                  {item.quantity}×&nbsp;{item.title}
                </span>
                <br />
                <span className={classes.notes}>
                  {notesPreview(item.notes)}
                </span>
              </td>
              <td className={classes.numeral} width="30%">
                {currency.format(item.quantity * item.price)}
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td>Totaal boeken</td>
            <td className={classes.numeral} width="30%">
              {currency.format(amount)}
            </td>
          </tr>
        )}
      </tbody>
      <tbody className={classes.shipping}>
        <tr>
          <td>
            {!countryEditable ? (
              <React.Fragment>Verzendkosten</React.Fragment>
            ) : (
              <React.Fragment>
                Verzenden naar{" "}
                <select
                  name="country"
                  autoComplete="country"
                  value={country}
                  onChange={(e) => {
                    const value = e.target.value
                    if (value in Country) {
                      dispatch(updateCountry((Country as any)[value]))
                    } else {
                      throw new Error(`Unknown country code: ${value}`)
                    }
                  }}
                >
                  <option value={Country.NL}>Nederland</option>
                  <option value={Country.BE}>België</option>
                </select>
              </React.Fragment>
            )}
          </td>
          <td className={classes.numeral}>
            {shipping === null ? (
              <>&hellip;</>
            ) : (
              currency.format(items.length > 0 ? shipping : 0)
            )}
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td>Totaal</td>
          <td className={classes.numeral}>
            {shipping === null ? (
              <>&hellip;</>
            ) : (
              currency.format(items.length > 0 ? amount + shipping : 0)
            )}
          </td>
        </tr>
      </tfoot>
    </table>
  )
}

export default OrderSummary
