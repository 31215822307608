import { DirectusUser } from "@directus/sdk"
import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  SerializedError,
} from "@reduxjs/toolkit"

import * as api from "../../api"
import Schema from "fs23-schema"

export type Credentials = {
  email: string
  password: string
}

type UserState = {
  credentials: Credentials
  user?: DirectusUser<Schema>
  isLoading: boolean
  error?: string
  lastRequestId?: string
  showingLoginModal: boolean
  loginRedirectPath?: string
  showingResetModal: boolean
  accountStatus?: string
  didRequestReset: boolean
  didReset: boolean
  didUpdate: boolean
  canRefresh: boolean
  didRefresh: boolean
}

const initialState: UserState = {
  credentials: { email: "", password: "" },
  isLoading: false,
  showingLoginModal: false,
  showingResetModal: false,
  didRequestReset: false,
  didReset: false,
  didUpdate: false,
  canRefresh: localStorage.getItem("refresh") === "true",
  didRefresh: false,
}

export const register = createAsyncThunk("user/register", api.register)
export const activate = createAsyncThunk("user/activate", api.activate)
export const login = createAsyncThunk("user/login", api.login)
export const logout = createAsyncThunk("user/logout", api.logout)
export const refreshAuth = createAsyncThunk("user/refresh", api.refreshAuth)
export const fetchMe = createAsyncThunk("user/fetchMe", api.getMe)
export const requestReset = createAsyncThunk(
  "user/requestReset",
  api.requestPasswordReset
)
export const resetPassword = createAsyncThunk(
  "user/resetPassword",
  api.resetPassword
)
export const updateMe = createAsyncThunk("user/updateMe", api.updateMe)
export const saveAvatar = createAsyncThunk("user/saveAvatar", api.saveAvatar)

const handleRejection = (
  state: UserState,
  action: PayloadAction<unknown, string, { requestId: string }, SerializedError>
) => {
  switch (action.error.message) {
    case undefined:
      state.error = "Er gaat iets mis. Probeer het opnieuw."
      break
    case "Duplicate email or display_name in users collection":
      state.error =
        "Emailadres en/of naam is al geregistreerd door een gebruiker."
      break
    case "Not a pending account":
      state.error =
        "Deze activatielink is niet (meer) geldig. Probeer in te loggen."
      break
    default:
      state.error = action.error.message
      break
  }
  state.isLoading = false
  state.lastRequestId = action.meta.requestId
  state.didRequestReset = false
  state.didReset = false
  state.didUpdate = false
}

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateCredentials: (state, action: PayloadAction<Credentials>) => {
      state.credentials = action.payload
    },
    clearError: (state) => {
      state.error = undefined
    },
    showLogin: (
      state,
      action: PayloadAction<{ redirectAfterLogin: string } | undefined>
    ) => {
      state.credentials = { email: "", password: "" }
      state.showingLoginModal = true
      state.showingResetModal = false
      state.loginRedirectPath = action.payload?.redirectAfterLogin
    },
    dismissLogin: (state) => {
      state.showingLoginModal = false
    },
    showReset: (state) => {
      state.error = undefined
      state.showingResetModal = true
    },
    dismissReset: (state) => {
      state.showingResetModal = false
    },
    didRedirect: (state) => {
      state.loginRedirectPath = undefined
    },
    markFeedbackAsSeen: (state) => {
      state.didRequestReset = false
      state.didReset = false
      state.didUpdate = false
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(register.pending, (state) => {
        state.error = undefined
        state.isLoading = true
      })
      .addCase(register.fulfilled, (state, action) => {
        state.accountStatus = "registered"
        state.isLoading = false
        state.lastRequestId = action.meta.requestId
      })
      .addCase(register.rejected, (state, action) => {
        handleRejection(state, action)
      })

    builder
      .addCase(activate.pending, (state) => {
        state.error = undefined
        state.isLoading = true
      })
      .addCase(activate.fulfilled, (state, action) => {
        state.accountStatus = "activated"
        state.isLoading = false
        state.lastRequestId = action.meta.requestId
      })
      .addCase(activate.rejected, (state, action) => {
        state.user = undefined
        handleRejection(state, action)
      })

    builder
      .addCase(login.pending, (state) => {
        state.error = undefined
        state.isLoading = true
      })
      .addCase(login.fulfilled, (state, action) => {
        state.credentials = { email: "", password: "" }
        state.user = action.payload as any
        state.accountStatus = undefined
        state.isLoading = false
        state.lastRequestId = action.meta.requestId
        state.showingLoginModal = false
        state.showingResetModal = false
        state.didRequestReset = false
        state.error = undefined
        state.canRefresh = true
        localStorage.setItem("refresh", "true")
      })
      .addCase(login.rejected, (state, action) => {
        state.user = undefined
        handleRejection(state, action)
      })

    builder
      .addCase(fetchMe.pending, (state) => {
        state.error = undefined
        state.isLoading = true
      })
      .addCase(fetchMe.fulfilled, (state, action) => {
        state.user = action.payload as any
        state.accountStatus = undefined
        state.isLoading = false
      })
      .addCase(fetchMe.rejected, (state) => {
        state.user = undefined
        state.isLoading = false
      })

    builder
      .addCase(refreshAuth.pending, (state) => {
        state.error = undefined
        state.isLoading = true
      })
      .addCase(refreshAuth.fulfilled, (state, action) => {
        state.user = action.payload as any
        state.accountStatus = undefined
        state.isLoading = false
        state.didRefresh = true
      })
      .addCase(refreshAuth.rejected, (state) => {
        console.log("refresh rejected")
        state.user = undefined
        state.isLoading = false
        state.canRefresh = false
        localStorage.removeItem("refresh")
        state.didRefresh = true
      })

    builder
      .addCase(logout.fulfilled, (state) => {
        state.user = undefined
        state.error = undefined
        state.accountStatus = undefined
        state.canRefresh = false
        localStorage.removeItem("refresh")
      })
      .addCase(logout.rejected, (state, action) => {
        state.error = action.error.message
      })

    builder
      .addCase(requestReset.pending, (state) => {
        state.error = undefined
        state.isLoading = true
        state.didRequestReset = false
        state.credentials = { ...state.credentials, password: "" }
      })
      .addCase(requestReset.fulfilled, (state, action) => {
        state.isLoading = false
        state.didRequestReset = true
        state.lastRequestId = action.meta.requestId
      })
      .addCase(requestReset.rejected, handleRejection)

    builder
      .addCase(resetPassword.pending, (state) => {
        state.didReset = false
        state.error = undefined
        state.isLoading = true
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.isLoading = false
        state.didRequestReset = false
        state.didReset = true
        state.lastRequestId = action.meta.requestId
      })
      .addCase(resetPassword.rejected, handleRejection)

    builder
      .addCase(updateMe.pending, (state) => {
        state.isLoading = true
        state.error = undefined
        state.didUpdate = false
      })
      .addCase(updateMe.fulfilled, (state, action) => {
        state.isLoading = false
        state.user = action.payload as any
        state.didUpdate = true
      })
      .addCase(updateMe.rejected, handleRejection)

    builder
      .addCase(saveAvatar.pending, (state) => {
        state.isLoading = true
        state.error = undefined
        state.didUpdate = false
      })
      .addCase(saveAvatar.fulfilled, (state, action) => {
        state.isLoading = false
        state.user!.avatar = action.payload as any
        state.didUpdate = true
      })
      .addCase(saveAvatar.rejected, handleRejection)
  },
})

export const {
  clearError,
  didRedirect,
  dismissLogin,
  dismissReset,
  markFeedbackAsSeen,
  showLogin,
  showReset,
  updateCredentials,
} = userSlice.actions

export default userSlice.reducer
