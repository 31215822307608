import React, { useEffect } from "react"
import { useSelector, shallowEqual } from "react-redux"
import { useParams } from "react-router-dom"
import { Helmet } from "react-helmet-async"
import ReactMarkdown from "react-markdown"

import { AppState, useAppDispatch } from "../../store"
import { fetchBook } from "./booksSlice"
import type { Book, Strip } from "../../api/schema"
import { asset } from "../../api/files"

import classes from "./BookDetail.module.css"
import BookOrderButton from "./BookOrderButton"
import StripGallery from "../strip/StripGallery"
import { Container, Image, Spinner } from "../../components"
import { formatMonth } from "../../util/moment"

const BookDetail: React.FC = () => {
  const { slug } = useParams<{ slug: string }>()
  const dispatch = useAppDispatch()

  const { isLoading, error } = useSelector((state: AppState) => state.books)
  const book = useSelector(
    (state: AppState) => state.books.books.find((b) => b.slug === slug),
    shallowEqual
  )

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    dispatch(fetchBook(slug))
  }, [dispatch, slug])

  if (isLoading && book === undefined) {
    return <Spinner centered />
  }

  if (error || book === undefined) {
    return <div>{error ?? "Boek niet gevonden"}</div>
  }

  return (
    <Container className={classes.BookDetail}>
      <Helmet>
        <title>{book.title}</title>
        {book.description || book.blurb ? (
          <meta name="description" content={book.description ?? book.blurb!} />
        ) : null}
      </Helmet>
      <section>
        <BookFeature book={book} />
      </section>
      <BookQuotes book={book} />
      <BookStrips book={book} isLoading={isLoading} />
    </Container>
  )
}

const BookFeature = ({ book }: { book: Book }) => {
  const {
    author,
    blurb,
    cover_image,
    description,
    isbn,
    notes,
    published_on,
    publisher,
    strips_count,
    title,
  } = book

  return (
    <div className={classes.hero}>
      <div className={classes.coverContainer}>
        {
          cover_image && typeof cover_image == "object" ? (
            <Image className={classes.cover} {...asset(cover_image, title)} />
          ) : null /* TODO show empty illustration? */
        }
      </div>
      <div className={classes.contents}>
        <div className={classes.body}>
          <h1>{title}</h1>
          {description || blurb ? (
            <ReactMarkdown>{description ?? blurb!}</ReactMarkdown>
          ) : null}
        </div>

        <div className={classes.orderInfo}>
          <BookOrderButton
            book={book}
            showPrice
            className={classes.orderButton}
          />
          <ul className={classes.info}>
            {published_on && <li>{formatMonth(published_on)}</li>}
            {(strips_count ?? 0) > 0 && <li>{strips_count} strips</li>}
            {notes && <li>{notes}</li>}
            {author && (
              <li>
                <span className={classes.label}>Door</span> {author}
              </li>
            )}
            {publisher && (
              <li>
                <span className={classes.label}>Uitgegeven door</span>{" "}
                {publisher}
              </li>
            )}
            {isbn && (
              <li>
                <span className={classes.label}>ISBN</span> {isbn}
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  )
}

const BookStrips = ({
  book: { title, strips, slug },
  isLoading,
}: {
  book: Book
  isLoading: boolean
}) => {
  if (strips === null || strips.length === 0) {
    return isLoading ? <Spinner centered /> : null
  }
  if (typeof strips[0] == "number") return null
  return (
    <section>
      <h2>Inhoud</h2>
      <p>Enkele strips uit {title}:</p>
      <StripGallery
        strips={
          strips
            .filter(
              (strip) =>
                typeof strip == "object" && strip.status === "published"
            )
            .slice(0, 5) as Strip[]
        }
        path={`/winkel/${slug}`}
      />
    </section>
  )
}

const BookQuotes = ({ book: { quotes } }: { book: Book }) => {
  if (!quotes || !quotes.length) {
    return null
  }
  return (
    <div className={classes.BookQuotes}>
      {quotes.map((quote, index) => (
        <blockquote key={index}>
          {quote.text}
          <br />
          <cite>{quote.source}</cite>
        </blockquote>
      ))}
    </div>
  )
}

export default BookDetail
