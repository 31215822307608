import React, { useEffect } from "react"
import { shallowEqual, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import type { Book } from "fs23-schema"

import { fetchOne } from "./seriesSlice"
import { AppState, useAppDispatch } from "../../store"

import StripGallery from "../strip/StripGallery"
import { Notice, PageContainer, Spinner } from "../../components"
import { asset } from "../../api/files"
import { BooksSection } from "../books/BooksSection"

const Series = () => {
  const { slug } = useParams<{ slug: string }>()
  const dispatch = useAppDispatch()
  const series = useSelector(
    (state: AppState) => state.series.allSeries.find((s) => s.slug === slug),
    shallowEqual
  )
  const { strips, error, isLoading } = useSelector(
    (state: AppState) => state.series
  )

  // Fetch this series.
  useEffect(() => {
    dispatch(fetchOne(slug))
  }, [dispatch, slug])

  return (
    <>
      <PageContainer
        header={{
          title: series?.title ?? "",
          introduction: series?.description,
          imageURL:
            series?.cover_image && typeof series.cover_image == "object"
              ? asset(series.cover_image, series.title).src
              : undefined,
          metaDescription: series?.payoff ?? series?.description,
        }}
      >
        {isLoading ? (
          <Spinner centered />
        ) : error || !series ? (
          <Notice>{error ?? "ERROR"}</Notice>
        ) : strips.length ? (
          <section>
            <StripGallery strips={strips} path={`/series/${series.slug}`} />
          </section>
        ) : null}
      </PageContainer>

      {series?.books?.length && typeof series.books[0] == "object" ? (
        <BooksSection
          header="Boeken"
          series={series}
          books={series.books as Book[]}
        />
      ) : null}
    </>
  )
}

export default Series
